<template>
    <r-e-dialog title="处理" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
                :footerBtnName="['取消','提交']" fullscreen
                @click-submit="clickSubmit(1)" @click-cancel="clickCancel" @close="clickCancel">
        <div style="height: 79vh;">
            <el-steps :active="0" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                <el-step title="楼栋管理员申请">
                    <div slot="description">
                        <div>申请人: {{ submitter }}</div>
                    </div>
                </el-step>
                <el-step title="资产部经理审核"></el-step>
                <el-step title="财务部审核"></el-step>
                <el-step title="管理层审核"></el-step>
                <el-step title="查看"></el-step>
            </el-steps>
            <div style="height: 90%;width: 100%;">
                <div style="height: 100%;display: flex;flex-flow: column wrap;">
                    <div class="boder-right width33b padding_0_30">
                        <div class="title">房源地址</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">{{ checkOurData.aprtmentName }}</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">租户信息</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">{{ checkOurData.leasor }}</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">退房</div>
                    </div>

                    <div class="flex boder-right width33b padding_0_30">
                        <div
                            style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;margin-right: 25px">
                            <span>合同退房时间 </span>
                            <span>{{ endDate }}</span>
                        </div>
                        <div style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;">
                            <span>实际退房时间 </span>
                            <span v-if="!checkOurDateShow" @click="setCheckOurDate">
                                {{ checkOurData.checkOutDate }}</span>
                            <span v-else>
                                <el-date-picker
                                    ref="checkOutDate" v-model="checkOurData.checkOutDate" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd" type="date" size="mini" @blur="checkOurDateBlur"
                                    placeholder="选择日期"/>
                            </span>
                        </div>
                    </div>

                    <div class="flex boder-right width33b padding_0_30" style="margin-top: 5px"
                         v-if="checkOurData.reasonCode===1">
                        <div
                            style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;margin-right: 25px">
                            <span>优惠金额 </span>
                            <span> {{ numberFormat(ticket.amount || 0) }}</span>
                        </div>
                    </div>

                    <div class="boder-right width33b fw600 padding_10_30">应退费用</div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">费用类型</div>
                            <div class="text-center width300">费用周期</div>
                            <div class="text-right width100">合计</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.depositCount<=1" :value="checkOurData.depositCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('押金',checkOurData.depositUuids)">押金
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.depositDate }}</div>
                            <div class="text-right width100">{{ checkOurData.depositAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.rentalCount<=1" :value="checkOurData.rentalCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应退房租',checkOurData.rentalUuids)">房租
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.rentalDate }}</div>
                            <div class="text-right width100">{{ checkOurData.rentalAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.serviceCount<=1" :value="checkOurData.serviceCount">
                                    <div style="width: 50px;">
                                        <el-link type="primary" @click="goBill('应退服务费',checkOurData.serviceUuids)">服务费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.serviceDate }}</div>
                            <div class="text-right width100">{{ checkOurData.serviceAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">房租余额</div>
                            <div class="text-center">----</div>
                            <div class="text-right width100">
                                <!-- 2024/04/19 张晓瑜修改为房租余额不可修改 -->
                                <div  class="text-right width100">
                                 <div> {{ checkOurData.rentalBalance }}</div>
                                </div>
                                <!-- <span v-if="!rentalBalanceShow"
                                      @click="amountClick('rentalBalanceShow','rentalBalance')">
                                    {{ checkOurData.rentalBalance }}
                                </span> -->
                                <!-- <el-input v-else v-model="checkOurData.rentalBalance" ref="rentalBalance"
                                          type="number" placeholder="请输入内容" size="mini"
                                          @blur="amountBlur('rentalBalanceShow','rentalBalance')"/> -->
                            </div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">杂费余额</div>
                            <div class="text-center width300">----</div>
                             <!-- 2024/04/19 张晓瑜修改为余额杂费不可修改 -->
                            <div  class="text-right width100">
                                 <div> {{ checkOurData.expenseBalance }}</div>
                                </div>
                            <!-- <div class="text-right width100">
                                <span v-if="!expenseBalanceShow"
                                      @click="amountClick('expenseBalanceShow','expenseBalance')">
                                    {{ checkOurData.expenseBalance }}
                                </span>
                                <el-input v-else v-model="checkOurData.expenseBalance" ref="expenseBalance"
                                          type="number" placeholder="请输入内容" size="mini"
                                          @blur="amountBlur('expenseBalanceShow','expenseBalance')"/>
                            </div> -->
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>应退租金额（元）
                                <span class="fw600" style="margin-left: 20px">{{ checkOurData.refundAmopunt }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">退租原因</div>
                        <el-button :type="checkOurData.reasonCode===1?'primary':''" @click="setReason(1,'到期')"
                                   style="font-size: 14px; padding: 8px 15px;">到 期
                        </el-button>
                        <el-button :type="checkOurData.reasonCode===2?'primary':''" @click="setReason(2,'违约')"
                                   style="font-size: 14px; padding: 8px 15px;">违 约
                        </el-button>
                    </div>

                    <div class="boder-right width33b padding_10_30 fw600">应扣费用</div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">费用类型</div>
                            <div class="text-center width300">费用周期</div>
                            <div class="text-right width100">合计</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidRentalCount<=1"
                                          :value="checkOurData.noPaidRentalCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应扣房租',checkOurData.noPaidRentalUuids)">
                                            房租
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidRentalDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidRentalAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidServiceCount<=1"
                                          :value="checkOurData.noPaidServiceCount">
                                    <div style="width: 50px;">
                                        <el-link type="primary"
                                                 @click="goBill('应扣服务费',checkOurData.noPaidServiceUuids)">
                                            服务费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidServiceDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidServiceAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidWaterCount<=1"
                                          :value="checkOurData.noPaidWaterCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应扣水费',checkOurData.noPaidWaterUuids)">
                                            水费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidWaterDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidWaterAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">
                                <el-badge :hidden="checkOurData.noPaidElectCount <= 1"
                                          :value="checkOurData.noPaidElectCount">
                                    <div style="width: 35px;">
                                        <el-link type="primary" @click="goBill('应扣电费',checkOurData.noPaidElectUuids)">
                                            电费
                                        </el-link>
                                    </div>
                                </el-badge>
                            </div>
                            <div class="text-center width300">{{ checkOurData.noPaidElectDate }}</div>
                            <div class="text-right width100">{{ checkOurData.noPaidElectAmount }}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">清洁费</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">
                                <span v-if="!cleanShow" @click="amountClick('cleanShow','clean')">
                                    {{ checkOurData.clean }}
                                </span>
                                <el-input v-else v-model="checkOurData.clean" ref="clean" type="number" size="mini"
                                          placeholder="请输入内容" @blur="amountBlur('cleanShow','clean')"/>
                            </div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">家具损坏费</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">
                                <span v-if="!damageShow" @click="amountClick('damageShow','damage')">
                                    {{ checkOurData.damage }}
                                </span>
                                <el-input v-else v-model="checkOurData.damage" ref="damage" type="number" size="mini"
                                          placeholder="请输入内容" @blur="amountBlur('damageShow','damage')"/>
                            </div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">其他</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">
                                <span v-if="!otherShow" @click="amountClick('otherShow','other')">
                                    {{ checkOurData.other }}
                                </span>
                                <el-input v-else v-model="checkOurData.other" ref="other" type="number" size="mini"
                                          placeholder="请输入内容" @blur="amountBlur('otherShow','other')"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="checkOurData.reasonCode===2" class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row">
                            <div class="text-left width100">违约金</div>
                            <div class="text-center width300">----</div>
                            <div class="text-right width100">
                                <span v-if="!deditShow" @click="amountClick('deditShow','other')">
                                    {{ checkOurData.dedit }}
                                </span>
                                <el-input v-else v-model="checkOurData.dedit" ref="dedit" type="number" size="mini"
                                          placeholder="请输入内容" @blur="amountBlur('deditShow','dedit')"/>
                            </div>
                        </div>
                    </div>
                     <!-- 到期的 -->
                    <div class="boder-right width33b padding_0_30" v-if="checkOurData.reasonCode === 2">
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>扣除租客金额（元）
                                <span class="fw600" style="margin-left: 20px">{{ checkOurData.payAmount }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 违约的 -->
                    <div class="boder-right width33b padding_0_30" v-else>
                        <div class="flex justify-between row bg-E6E6E6">
                            <div>扣除租客金额（元）
                                <span class="fw600" style="margin-left: 20px">{{ checkOurData.payAmount1 }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 到期的 -->
                    <div class="flex align-baseline width33b padding_10_30 fw600" v-if="checkOurData.reasonCode === 2">
                        <div style="width: 50px;">总计:</div>
                        <div>
                            <div style="font-size: 24px;">{{ checkOurData.totalAmountA }}</div>
                            <div v-if="checkOurData.totalAmount < 0" style="font-size: 12px;color:#FF6740;">
                                退还给租客{{ -checkOurData.totalAmountA }}
                            </div>
                            <div v-else style="font-size: 12px;color:#FF6740;">向租客收取{{ checkOurData.totalAmountA }}</div>
                        </div>
                    </div>
                    <!-- 违约的 -->
                    <div class="flex align-baseline width33b padding_10_30 fw600" v-else>
                        <div style="width: 50px;">总计:</div>
                        <div>
                            <div style="font-size: 24px;">{{ checkOurData.totalAmount }}</div>
                            <div v-if="checkOurData.totalAmount < 0" style="font-size: 12px;color:#FF6740;">
                                退还给租客{{ -checkOurData.totalAmount }}
                            </div>
                            <div v-else style="font-size: 12px;color:#FF6740;">向租客收取{{ checkOurData.totalAmount }}</div>
                        </div>
                    </div>

                    <div class="width33b padding_10_30">
                        <div class="fw600" style="margin-bottom: 10px">
                            上传图片
                        </div>
                        <div style="padding-left: 20px;">
                            <upload-picture-card :uuidList="imgList" :limit="24" @on-success="handleSuccess"
                                                 @on-remove="handleRemove"/>
                        </div>
                    </div>

                    <div class="width33b padding_10_30">
                        <div class="fw600" style="padding-bottom: 10px">
                            备注
                        </div>
                        <el-input type="textarea" v-model="checkOurData.comment" :rows="3" style="width: 100%;"
                                  placeholder="请输入内容"/>
                    </div>

                    <div v-if="checkOurData.totalAmount < 0" class="width33b padding_10_30">
                        <div class="title">
                            退还
                        </div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px">
                                汇款人 :
                            </div>
                            <div>
                                <el-input v-model="checkOurData.remitter" size="mini" placeholder="请输入内容"/>
                            </div>
                        </div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px">
                                银行卡号:
                            </div>
                            <div>
                                <el-input v-model="checkOurData.cardNum" size="mini" placeholder="请输入内容"/>
                            </div>
                        </div>
                        <div style="margin: 5px 0" class="flex align-center">
                            <div style="width: 80px;margin:0 15px">
                                开户行:
                            </div>
                            <div>
                                <el-input v-model="checkOurData.bank" size="mini" placeholder="请输入内容"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <r-e-dialog :title="title" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                    @click-cancel="billListClose" v-dialog-drag>
            <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                       show-summary :summary-method="getSummaries">
                <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
                <!--<el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="update(row)">修改</span>
                        </div>
                    </template>
                </el-table-column>-->
            </r-e-table>
        </r-e-dialog>

        <dialog-add-bill ref="dialogAddBill" @clickCancel="billClickCancel"/>
        <el-button slot="footer" class="submit" @click="clickSubmit(0)">保存</el-button>
    </r-e-dialog>
</template>

<script>
import {MessageInfo, MessageSuccess} from "@custom/message";
import {timeFormat, numberFormat} from "@custom/index";
import {getContractsCheckoutData, getCheckoutInfo, contractsCheckout, contractCheckoutExit} from "@/api/contract";
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import {houseBillColumns} from "@/views/rental-management/house-resource/data";
import {getPaybillUuidsList} from "@/api/bill";
import {getAticityTicketMe} from "@/api/activity-management";

export default {
    name: "dialog-check-out",
    data() {
        return {
            dialogVisible: false,
            imgList: [],
            checkOurDateShow: false,
            endDate: null,
            uuid: null,
            checkOurData: {
                aprtmentName: '0',
                leasor: '0',
                checkOutDate: null,
                depositAmount: null,
                depositDate: null,
                rentalDate: null,
                rentalAmount: null,
                serviceDate: null,
                serviceAmount: null,
                rentalBalance: null,
                expenseBalance: null,
                noPaidRentalDate: null,
                noPaidRentalAmount: null,
                noPaidServiceDate: null,
                noPaidServiceAmount: null,
                noPaidWaterDate: null,
                noPaidWaterAmount: null,
                noPaidElectDate: null,
                noPaidElectAmount: null,
                clean: '0.00',
                damage: '0.00',
                other: '0.00',
                dedit: '0.00',
                payAmount: 0,
                refundAmopunt: 0,
                totalAmount: 0,
                reasonCode: 1,
                reasonName: '到期',
                remitter: '',
                bank: "",
                cardNum: '',
                comment: '',
            },
            rentalAmountShow: false,
            serviceAmountShow: false,
            rentalBalanceShow: false,
            expenseBalanceShow: false,
            noPaidRentalAmountShow: false,
            noPaidServiceAmountShow: false,
            noPaidWaterAmountShow: false,
            noPaidElectAmountShow: false,
            cleanShow: false,
            damageShow: false,
            otherShow: false,
            deditShow: false,
            contractNum: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            title: "",
            billSwitch: false,
            houseBillColumns: houseBillColumns(this),
            billList: [],
            billUuids: null,
            submitter: null,
            ticket: {}
        };
    },
    components: {
        uploadPictureCard,
        dialogAddBill: () => import("@/views/rental-management/house-resource/components/part/components/dialog-add-bill"),
    },
    props: {},
    methods: {
        //打开退房弹框
        async openDialog(data) {
            // 获取合同uuid，合同结束时间，合同编号
            const {uuid, endDate, contractNum} = data
            // 保存数据
            this.uuid = uuid;
            this.contractNum = contractNum;
            //日期转换
            this.endDate = timeFormat(new Date(endDate));
            //设置默认退房时间
            this.checkOurData.checkOutDate = timeFormat(new Date());
            // 调用退房信息方法查询退房信息
            await this.getCheckoutInfo();
            //获取退房申请人，当前登录人
            this.submitter = localStorage.getItem("userName");
            this.dialogVisible = true;
        },
        // 退房信息方法
        async getCheckoutInfo() {
            const that = this;
            let uuid = this.uuid;
            let {checkOutDate} = this.checkOurData;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //调用接口，查询退房信息
            let {info} = await getCheckoutInfo({uuid, checkOutDate}).catch(() => loading.close());
            //获取退房信息，解构出所需数据
            let {
                depositAmount, depositDate, rentalDate, rentalAmount, serviceDate, serviceAmount, rentalBalance,
                expenseBalance, noPaidRentalDate, noPaidRentalAmount, noPaidServiceDate, noPaidServiceAmount,
                noPaidWaterDate, noPaidWaterAmount, noPaidElectDate, noPaidElectAmount, aprtmentName, leasor,
                noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                serviceUuids, depositCount, depositUuids
            } = info;
            //金额转换
            depositAmount = parseFloat((depositAmount / 100)).toFixed(2);
            rentalAmount = parseFloat(rentalAmount / 100).toFixed(2);
            serviceAmount = parseFloat(serviceAmount / 100).toFixed(2);
            rentalBalance = parseFloat(rentalBalance / 100).toFixed(2);
            expenseBalance = parseFloat(expenseBalance / 100).toFixed(2);
            noPaidRentalAmount = parseFloat(noPaidRentalAmount / 100).toFixed(2);
            noPaidServiceAmount = parseFloat(noPaidServiceAmount / 100).toFixed(2);
            noPaidWaterAmount = parseFloat(noPaidWaterAmount / 100).toFixed(2);
            noPaidElectAmount = parseFloat(noPaidElectAmount / 100).toFixed(2);
            //保存旧数据
            const oldCheckOurData = this.checkOurData;
            //拆分旧数据以免字段被移除，打包退房信息，并赋值
            this.checkOurData = {
                ...oldCheckOurData, depositAmount, depositDate, rentalDate, rentalAmount, serviceDate,
                serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                noPaidElectAmount, aprtmentName, leasor, noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount,
                noPaidServiceUuids, noPaidWaterCount, noPaidWaterUuids, noPaidElectCount, noPaidElectUuids,
                rentalCount, rentalUuids, serviceCount, serviceUuids, depositCount, depositUuids
            }
            //调用方法计算应退费用合记
            this.calculationRefundAmopunt();
            //调用方法计算应扣费用合记
            this.calculationpPayAmount();
            loading.close();
        },
        // 修改退房时间方法
        setCheckOurDate() {
            //显示退房时间组件
            this.checkOurDateShow = true;
            //渲染完成后光标选中
            this.$nextTick(() => this.$refs["checkOurDate"].focus());
        },
        //退房时间确认后处理事件
        checkOurDateBlur() {
            //隐藏退房时间组件
            this.checkOurDateShow = false;
            // 选择退房时间后，重新加载退房数据
            this.getCheckoutInfo();
        },
        //点击编辑金额
        amountClick(name, ref) {
            //显示组件
            this[name] = true;
            //组件渲染完成后光标选中
            this.$nextTick(() => this.$refs[ref].focus());
        },
        //输入框光标移除事件
        amountBlur(name, ref) {
            //隐藏组件
            this[name] = false;
            //金额格式化
            this.checkOurData[ref] = parseFloat(this.checkOurData[ref] || 0).toFixed(2);
            //调用方法计算应退费用合记
            this.calculationRefundAmopunt();
            //调用方法计算应扣费用合记
            this.calculationpPayAmount();
        },
        //退租原因切换方法
        setReason(code, name) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading(loadingOptions);
            // 获取优惠券
            getAticityTicketMe(this.uuid, code).then(res => {
                //获取返回优惠券信息
                let {info = {}} = res;
                //保存旧优惠券信息
                let ticket = that.ticket;
                //拆分数据保存优惠券信息
                that.ticket = {...ticket, ...info}
                //调用方法计算应扣费用合记
                this.calculationpPayAmount();
            }).finally(() => loading.close());
            //设置退租类型
            this.checkOurData.reasonCode = code;
            this.checkOurData.reasonName = name;
            // this.checkOurData.dedit = 0;
        },
        // 计算应退费用合记方法
        calculationRefundAmopunt() {
            //解构数据
            let {depositAmount, rentalAmount, serviceAmount, rentalBalance, expenseBalance} = this.checkOurData;
            //金额转换
            depositAmount = parseFloat(depositAmount);
            rentalAmount = parseFloat(rentalAmount);
            serviceAmount = parseFloat(serviceAmount);
            rentalBalance = parseFloat(rentalBalance);
            expenseBalance = parseFloat(expenseBalance);
            //计算应退费用合记
            this.checkOurData.refundAmopunt = (depositAmount + rentalAmount + serviceAmount + rentalBalance +
                expenseBalance).toFixed(2);
            //调用方法计算总费用合记
            this.calculationpTotalAmount();
        },
        // 计算应退扣用合记方法
        calculationpPayAmount() {
            //解构数据   totalAmount
            let {
                noPaidRentalAmount, noPaidServiceAmount, noPaidWaterAmount, noPaidElectAmount, clean, damage, other,
                dedit
            } = this.checkOurData;
            //转换金额
            noPaidRentalAmount = parseFloat(noPaidRentalAmount);
            noPaidServiceAmount = parseFloat(noPaidServiceAmount);
            noPaidWaterAmount = parseFloat(noPaidWaterAmount);
            noPaidElectAmount = parseFloat(noPaidElectAmount);
            clean = parseFloat(clean);
            damage = parseFloat(damage);
            other = parseFloat(other);
            dedit = parseFloat(dedit);
            // 计算应退扣用合记
            this.checkOurData.payAmount = (noPaidRentalAmount + noPaidServiceAmount + noPaidWaterAmount +
                noPaidElectAmount + clean + damage  + dedit + other).toFixed(2);
            this.checkOurData.payAmount1 = (noPaidRentalAmount + noPaidServiceAmount + noPaidWaterAmount +
                noPaidElectAmount + clean + damage + dedit  + other).toFixed(2);
            //调用方法计算总费用合记
            this.calculationpTotalAmount();
        },
        //计算总费用合记方法
        calculationpTotalAmount() {
            //获取应退费用
            let refundAmopunt = this.checkOurData.refundAmopunt;
            //获取应扣费用
            // 违约的
            let payAmount = this.checkOurData.payAmount;
            // 到期的
            let payAmount1 = this.checkOurData.payAmount1;
            //获取优惠券费用
            let {amount = 0} = this.ticket;
            //转换优惠券金额
            amount = parseFloat((amount / 100).toFixed(2));
            //计算总费用合记 应退-应扣-优惠券金额
            this.checkOurData.totalAmountA = (parseFloat(payAmount) - parseFloat(refundAmopunt) - amount).toFixed(2);
            this.checkOurData.totalAmount = (parseFloat(payAmount1) - parseFloat(refundAmopunt) - amount).toFixed(2);
        },
        // 点击确认按钮事件
        clickSubmit(workOrderStatus) {
            let that = this;
            //解构数据
            let {
                aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount, reasonCode,
                reasonName, remitter, bank, cardNum, comment, noPaidRentalCount, noPaidRentalUuids, paymentMethod,
                noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount, noPaidWaterUuids, noPaidElectCount,
                noPaidElectUuids, rentalCount, rentalUuids, serviceCount, serviceUuids, depositCount, depositUuids,
            } = that.checkOurData;
            //金额转换
            depositAmount = parseInt((parseFloat(depositAmount) * 100).toFixed(0));
            rentalAmount = parseInt((parseFloat(rentalAmount) * 100).toFixed(0));
            serviceAmount = parseInt((parseFloat(serviceAmount) * 100).toFixed(0));
            rentalBalance = parseInt((parseFloat(rentalBalance) * 100).toFixed(0));
            expenseBalance = parseInt((parseFloat(expenseBalance) * 100).toFixed(0));
            noPaidRentalAmount = parseInt((parseFloat(noPaidRentalAmount) * 100).toFixed(0));
            noPaidServiceAmount = parseInt((parseFloat(noPaidServiceAmount) * 100).toFixed(0));
            noPaidWaterAmount = parseInt((parseFloat(noPaidWaterAmount) * 100).toFixed(0));
            noPaidElectAmount = parseInt((parseFloat(noPaidElectAmount) * 100).toFixed(0));
            clean = parseInt((parseFloat(clean) * 100).toFixed(0));
            damage = parseInt((parseFloat(damage) * 100).toFixed(0));
            other = parseInt((parseFloat(other) * 100).toFixed(0));
            dedit = parseInt((parseFloat(dedit) * 100).toFixed(0));
            payAmount = parseInt((parseFloat(payAmount) * 100).toFixed(0));
            refundAmopunt = parseInt((parseFloat(refundAmopunt) * 100).toFixed(0));
            totalAmount = parseInt((parseFloat(totalAmount) * 100).toFixed(0));

            if (workOrderStatus === 1) {
                // 退租原因校验
                if (reasonCode === null || reasonCode === '') {
                    MessageInfo('请选择退租原因');
                    return;
                }

                //退租总金额为负，则需要退钱给租户
                if (totalAmount < 0) {
                    if (remitter === null || remitter === '') {
                        MessageInfo('请填写汇款人');
                        return;
                    }
                    if (cardNum === null || cardNum === '') {
                        MessageInfo('请填写银行卡号');
                        return;
                    }
                    if (bank === null || bank === '') {
                        MessageInfo('请填写开户行');
                        return;
                    }
                }

                if (totalAmount > 0) {
                    if (paymentMethod === null || paymentMethod === '') {
                        MessageInfo('请选择付款方式');
                        return;
                    }
                }

                // 获取退房图片
                let workPictures = that.imgList.join(";");
                //获取退房合同uuid
                let contractUuid = that.uuid;
                //封装数据
                let data = {
                    aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                    serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                    noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                    noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount,
                    reasonCode, reasonName, remitter, bank, cardNum, comment, workPictures, contractUuid, paymentMethod,
                    noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                    noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                    serviceUuids, depositCount, depositUuids, workOrderStatus,
                };
                //退房提示
                const h = this.$createElement;
                this.$confirm(h('div', {style: {color: 'red'}}, '请确认用户的账单时间与金额，确认后无法进行变更！'), '注意', {type: 'warning'}).then(() => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    //调用接口提交退房
                    contractCheckoutExit(data).then(res => {
                        loading.close();
                        MessageSuccess((workOrderStatus === 1 ? '提交' : '保存') + '成功');
                        //调用取消方法，关闭弹窗
                        that.clickCancel();
                    }).catch(() => loading.close());
                }).catch(() => {
                });
            }
            if (workOrderStatus === 0) {
                // 获取退房图片
                let workPictures = that.imgList.join(";");
                //获取退房合同uuid
                let contractUuid = that.uuid;
                //封装数据
                let data = {
                    aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                    serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                    noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                    noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount, reasonCode,
                    reasonName, remitter, bank, cardNum, comment, workPictures, contractUuid, paymentMethod,
                    noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                    noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                    serviceUuids, depositCount, depositUuids, workOrderStatus,
                };
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                console.log('保存提交数据data',data);
                //调用接口提交退房
                contractCheckoutExit(data).then(res => {
                    loading.close();
                    MessageSuccess((workOrderStatus === 1 ? '提交' : '保存') + '成功');
                    //调用取消方法，关闭弹窗
                    that.clickCancel();
                }).catch(() => loading.close());
            }
         this.timer =  setInterval(() => {this.throttle = this.throttle + 1},1000)
          console.log(this.throttle)
        },

        //点击取消按钮事件
        clickCancel() {
            //调用上层方法，刷新合同列表
            this.$emit('handleSearch');
            //关闭弹窗
            this.dialogVisible = false;
            //重置数据
            this.checkOurData = {
                aprtmentName: '0', leasor: '0', checkOutDate: null, depositAmount: null, depositDate: null,
                rentalDate: null, rentalAmount: null, serviceDate: null, serviceAmount: null, rentalBalance: null,
                expenseBalance: null, noPaidRentalDate: null, noPaidRentalAmount: null, noPaidServiceDate: null,
                noPaidServiceAmount: null, noPaidWaterDate: null, noPaidWaterAmount: null, noPaidElectDate: null,
                noPaidElectAmount: null, clean: '0.00', damage: '0.00', other: '0.00', dedit: '0.00', payAmount: 0,
                refundAmopunt: 0, totalAmount: 0, reasonCode: 1, reasonName: '到期', remitter: '', bank: "", cardNum: '',
                comment: '',
            };
            this.imgList = [];
            this.uuid = null;
        },

        //退房图片上传成功回调
        handleSuccess({fileList}) {
            //获取全部上传成功图片uuid
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        //退房图片删除成功回调
        handleRemove({fileList}) {
            //获取全部剩余图片uuid
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        //获取账单列表
        async goBill(type, data) {
            if (data) {
                let that = this;
                that.title = type;
                that.billUuids = data;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                //调用接口获取账单列表
                let {list} = await getPaybillUuidsList({uuids: data}).catch(err => loading.close());
                that.billList = list;
                loading.close();
                //打开账单弹窗
                that.billSwitch = true;
            } else MessageInfo('无' + type + '账单');
        },

        // 打开修改账单弹窗
        update(data) {
            this.$refs["dialogAddBill"].openDialog(data);
        },

        // 关闭修改账单弹窗
        billClickCancel() {
            const {title, billUuids} = this;
            this.goBill(title, billUuids);
            this.getCheckoutInfo();
        },

        //账单列表弹窗关闭
        billListClose() {
            this.title = null;
            this.billUuids = null;
            this.billList = null;
            this.billSwitch = false;
        },

        //账单列表合计
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';

            });
            return sums;
        },

        //金额格式化方法
        numberFormat(number) {
            return numberFormat(Number(number) / 100);
        }
    },
    async created() {
    },
}
</script>

<style scoped>
.row {
    margin-bottom: 10px;
    padding: 10px 20px;
    border: 1px solid #D7D7D7;
}

.width300 {
    width: 300px;
}

.width100 {
    width: 100px;
}

.boder-right {
    border-right: 1px solid #D7D7D7;
}

.title {
    border-left: 5px #F5A623 solid;
    padding-left: 5px;
    margin: 15px 0;
    font-weight: 600;
}

.bg-E6E6E6 {
    background-color: #E6E6E6;
}

.bg-E2F5FF {
    background-color: #E2F5FF;
}

.bg-F8F8F8 {
    background-color: #F8F8F8;
}

.width33b {
    width: 33%;
}

.padding_0_30 {
    padding: 0 30px
}

.padding_10_30 {
    padding: 10px 30px;
}

.fw600 {
    font-weight: 600;
}
</style>
